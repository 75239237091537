<template>
  <b-form-group :label="label" :state="state">
    <template v-if="label" v-slot:label>
      {{ label }}
      <span v-if="required" class="text-danger"> (*) </span>
    </template>
    <flat-pickr
      :id="id"
      v-model="date"
      :placeholder="placeholder"
      class="form-control"
      :config="config"
      @input="onChange"
    />
    <small class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { BFormGroup } from "bootstrap-vue";
import { parseDateToString } from "@/auth/utils";
import { computed } from "@vue/composition-api";

export default {
  components: {
    BFormGroup,
    flatPickr,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: [String, Date, null],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      date: parseDateToString(this.defaultValue),
    };
  },
  watch: {
    defaultValue(val) {
      this.date = val;
    },
  },
  methods: {
    onChange() {
      this.$emit("input", this.date);
      // this.$emit('on-date-change', this.date);
    },
    onClose() {},
  },
  setup(props) {
    const config = computed(() => {
      return {dateFormat: "d-m-Y",
      allowInput: true,
      disable: props.disable,}
    });

    return { config };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
